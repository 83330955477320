import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import {
  EstimationRequest,
  IntentResponse,
  UnifiedCheckoutEstimatationRequest,
  UnifiedCheckoutEstimatationResponse
} from "../model/pay.model";
import { Observable } from 'rxjs';

@Injectable()
export class PayService {
  
  private createIntentApi = `${environment.legacyEndpoint}/api/v1/stripe/create-intent`;
  private confirmIntentApi = `${environment.legacyEndpoint}/api/v1/stripe/confirm-intent`;
  private getStripePublicKeyByCountryApi = `${environment.legacyEndpoint}/api/v1/stripe/public-key/by-country`;

  private planIdApi = `${environment.legacyEndpoint}/api/v1/subscriptions/plans`;
  private couponApi = `${environment.legacyEndpoint}/api/v1/coupons`;
  private estimationApi = `${environment.legacyEndpoint}/api/v1/subscriptions/estimate/create`;
  private unifiedCheckoutEstimationApi = `${environment.legacyEndpoint}/api/v1/onboardings/estimate`;

  constructor(private http: HttpClient) { }

  getPlanById(id: string) {
    return this.http.get(`${this.planIdApi}/${id}`);
  }

  getCouponByCountry(id: string, countryCode: string, chargebeeAccount?: string) {
    let queryParam = chargebeeAccount ? `chargebee_account=${chargebeeAccount}` : `country_code=${countryCode}`
    return this.http.get(`${this.couponApi}/${id}?${queryParam}`);
  }

  getPaymentEstimation(estimationRequest: EstimationRequest) {
    return this.http.post(this.estimationApi, estimationRequest);
  }

  // TODO:  Add the amount when the estimation is available.
  //Returns a promise for consinstency because stripe api use a promise
  createIntent(paymentMethodId: any, stripeKey: string) {
    const body = {
      payment_method_id: paymentMethodId,
      stripe_public_key: stripeKey
    };
    return this.http
      .post<IntentResponse>(this.createIntentApi, body)
      .toPromise();
  }

  //Returns a promise for consinstency because stripe api use a promise
  confirmIntent(paymentMethodId: any, paymentIntentId: any, stripeKey: string) {
    const body = {
      payment_method_id: paymentMethodId,
      payment_intent_id: paymentIntentId,
      stripe_public_key: stripeKey
    };
    return this.http
      .post<IntentResponse>(this.confirmIntentApi, body)
      .toPromise();
  }

  getStripePublicKeyByCountry(country: string) {
    return this.http.get<{ public_key: string }>(`${this.getStripePublicKeyByCountryApi}/${country.toUpperCase()}`);
  }

  getErrorToken(err, token) {
    const code = err.error.body.code;
    const supportedErrorCodes = [
      'generic_decline',
      'insufficient_funds',
      'lost_card',
      'stolen_card',
      'expired_card',
      'incorrect_cvc',
      'processing_error',
      'incorrect_number'
    ];

    return supportedErrorCodes.includes(code)
      ? `${token}.${code}`
      : `${token}.general`
  }


  // romulus stripe implementation
  getRomulusStripePublicKey(country: string = 'IT') {
    // not sure we can get the stripe public key for other countries
    return this.http.get<{ public_key: string}>(`${environment.romulusBaseEndpoint}/v1/stripe/public-key?countryCode=${country}`);
  }

  getRomulusStripePaymentIntent(paymentMethodId: any, stripeKey: string) {
    const body = {
      payment_method_id: paymentMethodId,
      stripe_public_key: stripeKey
    };
    return this.http.post<IntentResponse>(`${environment.romulusBaseEndpoint}/v1/stripe/intent/card`, body).toPromise();
  }

  getRomulusStripePaymentIntentConfirm(paymentMethodId: any, paymentIntentId: any, stripeKey: string) {
    const body = {
      payment_method_id: paymentMethodId,
      payment_intent_id: paymentIntentId,
      stripe_public_key: stripeKey
    };
    return this.http.post<IntentResponse>(`${environment.romulusBaseEndpoint}/v1/stripe/intent/confirmation`, body).toPromise();
  }
  
  public getUnifiedCheckoutEstimation(estimation: UnifiedCheckoutEstimatationRequest): Observable<UnifiedCheckoutEstimatationResponse> {
    return this.http.post<UnifiedCheckoutEstimatationResponse>(this.unifiedCheckoutEstimationApi, estimation);
  }
}
