import { Injectable } from "@angular/core";
import { ActivatedRoute, Router, Routes } from "@angular/router";
import { AppFacade } from "../store";
import { take } from "rxjs/operators";
import { I18nService } from 'src/app/core/i18n';
import { flowStepsConfig } from '../model/app.model';
import { AuthGuard } from '../../auth/guards/auth.guard';

@Injectable()
export class AppService {
  public dealID: string;
  public referrerId: string;
  public partnerID: string;
  public agentID: string;
  public country: string;
  public kazooInstanceId: string;
  public chargebeeAccount: string;
  currentStep: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appFacade: AppFacade,
    private i18nService: I18nService
  ) {}

  getRolesID() {
    if (this.activatedRoute.snapshot.queryParamMap.has("deal_id")) {
      this.dealID = this.activatedRoute.snapshot.queryParamMap.get("deal_id");
      this.appFacade.setDealId(this.dealID);
    } else {
      this.appFacade.dealID$.subscribe(id => {
        this.dealID = id;
      });
    }

    if (this.activatedRoute.snapshot.queryParamMap.has("referrer_id")) {
      this.referrerId = this.activatedRoute.snapshot.queryParamMap.get("referrer_id");
      this.appFacade.setReferrerID(this.referrerId);
    } else {
      this.appFacade.referrerID$.subscribe(id => {
        this.referrerId = id;
      });
    }

    if (this.activatedRoute.snapshot.queryParamMap.has("lang")) {
      const languageParam = this.activatedRoute.snapshot.queryParamMap.get("lang");
      this.i18nService.language = languageParam;
    }

    if (this.activatedRoute.snapshot.queryParamMap.has("partner")) {
      this.partnerID = this.activatedRoute.snapshot.queryParamMap.get(
        "partner"
      );
      this.appFacade.setPartnerID(this.partnerID);
    } else {
      this.appFacade.partnerID$.subscribe(id => {
        this.partnerID = id;
      });
    }

    if (this.activatedRoute.snapshot.queryParamMap.has("agent")) {
      this.agentID = this.activatedRoute.snapshot.queryParamMap.get("agent");
      this.appFacade.setAgentID(this.agentID);
    } else {
      this.appFacade.agentID$.subscribe(id => {
        this.agentID = id;
      });
    }

    if (this.activatedRoute.snapshot.queryParamMap.has("country")) {
      this.country = this.activatedRoute.snapshot.queryParamMap.get("country");
      this.appFacade.setInitialCountry(this.country);
    }

    if (this.activatedRoute.snapshot.queryParamMap.has("kazoo_instance_id")) {
      this.kazooInstanceId = this.activatedRoute.snapshot.queryParamMap.get("kazoo_instance_id");
      this.appFacade.setKazooInstanceId(this.kazooInstanceId);
    }

    if (this.activatedRoute.snapshot.queryParamMap.has("chargebee_account")) {
      this.chargebeeAccount = this.activatedRoute.snapshot.queryParamMap.get("chargebee_account");
      this.appFacade.setChargebeeAccountId(this.chargebeeAccount);
    }

    this.appFacade.step$.pipe(take(1)).subscribe(step => {
      this.currentStep = step.split("?")[0];
      this.appFacade.setStep(this.currentStep);
      this.appFacade.updateStepProgress(this.currentStep);
      this.router.navigate(this.currentStep.split("."), {
        relativeTo: this.activatedRoute,
        queryParams: {}
      });
    });
  }

  public generateRoutes(stepRouteList: Routes): Routes {
    const firstFlow = Object.keys(flowStepsConfig)[0];
    let result: Routes = [];
    Object.keys(flowStepsConfig).forEach(k => {
      const children = [];

      flowStepsConfig[k].steps.forEach(r => {
        children.push(stepRouteList.find(el => el.path === r));
      });

      children.push({
        path: "",
        redirectTo: flowStepsConfig[k].steps[0],
        pathMatch: "full"
      });
      if(flowStepsConfig[k]?.data?.auth) {
        result.push({
          path: k,
          canActivate: [AuthGuard],
          children
        });
      } else {
        result.push({
          path: k,
          children
        });
      }
    });
    const lang = navigator.language || navigator['userLanguage'] || navigator['browserLanguage'];
    if (lang.includes('it')) {
      result.push({ path: "**", redirectTo: "attiva_ora_unificato" });
      result.push({ path: "", redirectTo: firstFlow, pathMatch: "full" });
    } else {
      result.push({ path: "**", redirectTo: "attiva_ora_unificato" });
      result.push({ path: "", redirectTo: firstFlow, pathMatch: "full" });
    }
    return result;
  }
}
