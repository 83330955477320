import { FlowConfig } from './flow.model';

export const flowStepsConfigDebug: FlowConfig = {
  debug: {
    steps: [
      //"welcome-offer",
      //"welcome-direct-specialist",
      //"welcome-specialist",
      //"signup",
      //"verify-phone",
      //"verify-sms",
      "info",
      //"account-number",
      //"addon",
      //"starter-kit",
      //"assign-starter-kit",
      // "choose-plan4", // choose plan discount
      // "choose-plan5", // choose plan discount partner
      // "choose-plan6", // choos plan annual biennial
      // "choose-plan7", // choos plan annual biennial partner
      // "choose-plan2",
      // "choose-plan3",
      //"choose-plan9",
      //"wifi",
      //"wifi-full",
      //"pay",  // activate now
      //"payment-method", // free trial
      // "activation"
    ],
    data: {
      sendSms: true,
      initialCountry: 'IT',
      isRomulusFlow: true,
      freetrialPlan: {
        it: "it-trial-v2",
        gb: "gb-trial-v1",
        us: "us-trial-v1"
      },
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'US',
        },
        {
          iso2: 'GB'
        }
      ]
    }
  },
  prova_gratis_specialista: {
    steps: [
      "signup",
      "verify-phone",
      "verify-sms",
      "info",
      "starter-kit",
      "assign-starter-kit",
      "payment-method",
      "activation"
    ],
    data: {
      freetrialPlan: {
        it: "it-trial-v2",
        gb: "gb-trial-v1"
      },
      askPaymentMethod: false,
      linkTokenExp: "prova_gratis",
      linkTokenNotExist: "prova_gratis",
      sendSms: true,
      initialCountry: 'IT'
    }
  },
  free_trial_specialist: {
    steps: [
      "signup",
      "verify-phone",
      "verify-sms",
      "info",
      "starter-kit",
      "assign-starter-kit",
      "payment-method",
      "activation"
    ],
    data: {
      freetrialPlan: {
        it: "it-trial-v2",
        gb: "gb-trial-v1"
      },
      askPaymentMethod: false,
      linkTokenExp: "free_trial",
      linkTokenNotExist: "free_trial",
      sendSms: true,
      initialCountry: 'GB'
    }
  }
  // payment_method: ['signup', 'info', 'payment-method']
  // trial cc: activation less choose-plan, addon, activation-recap -> payment-method
  // trial no cc: trial cc no payment-method
}
