import { AppAction, AppActionTypes } from "../actions";
import {
  flowStepsConfig,
  getInitialStep,
  getFirstStep,
  getPrevStep,
  getNextStep,
  calculateProgress,
  getNextNStep
} from "../../model/app.model";
import { TokenResponse } from 'src/app/domains/auth/models/auth.model';

export interface State {
  locale: string;
  step: string;
  progress: number;
  flows: any;
  started: boolean;
  isToken: boolean;
  deal_id: string;
  referrer_id: string;
  partner_id: string;
  agent_id: string;
  initial_country: string;
  kazoo_instance_id: string;
  chargebee_account: string;
  api_token: TokenResponse;
  payment_methods_available: string[];
  send_sms: boolean;
}

export const initialState: State = {
  locale: "",
  step: getInitialStep(flowStepsConfig),
  progress: 0,
  flows: flowStepsConfig,
  isToken: false,
  started: false,
  deal_id: "",
  referrer_id: "",
  partner_id: "",
  agent_id: "",
  initial_country: "",
  kazoo_instance_id: "",
  chargebee_account: "",
  api_token: null,
  payment_methods_available: [],
  send_sms: true
};

export function reducer(state = initialState, action: AppAction): State {
  switch (action.type) {
    case AppActionTypes.SetLocale: {
      return {
        ...state,
        locale: action.payload
      };
    }
    case AppActionTypes.IncrementStep: {
      const newStep = getNextStep(state.step);
      return {
        ...state,
        progress: calculateProgress(newStep),
        step: newStep
      };
    }
    case AppActionTypes.DecrementStep: {
      const newStep = getPrevStep(state.step);
      return {
        ...state,
        progress: calculateProgress(newStep),
        step: newStep
      };
    }
    // TODO: Remove when we don't have to hide stater kits
    case AppActionTypes.SkipStarterKits: {
      let newStep = getNextStep(state.step);
      while (newStep.includes('starter-kit')) {
        newStep = getNextStep(newStep);
      }
      return {
        ...state,
        progress: calculateProgress(newStep),
        step: newStep
      };
    }
    case AppActionTypes.SkipNextNSteps: {
      let newStep = getNextNStep(state.step, action.payload);
      return {
        ...state,
        progress: calculateProgress(newStep),
        step: newStep
      };
    }
    case AppActionTypes.SilentIncrementStep: {
      const newStep = getNextStep(state.step);
      return {
        ...state,
        progress: calculateProgress(newStep),
        step: newStep
      };
    }
    case AppActionTypes.SilentDecrementStep: {
      const newStep = getPrevStep(state.step);
      return {
        ...state,
        progress: calculateProgress(newStep),
        step: newStep
      };
    }
    case AppActionTypes.JumpToFirstStep: {
      const newStep = getFirstStep(state.step);
      return {
        ...state,
        progress: calculateProgress(newStep),
        step: newStep
      };
    }
    case AppActionTypes.UpdateStepProgress: {
      const step = action.payload;
      return {
        ...state,
        step,
        progress: calculateProgress(step)
      };
    }
    case AppActionTypes.SetStep: {
      return {
        ...state,
        step: action.payload
      };
    }
    case AppActionTypes.SetIsToken: {
      return {
        ...state,
        isToken: action.isToken
      };
    }
    case AppActionTypes.SetStart: {
      return {
        ...state,
        started: action.started
      };
    }
    case AppActionTypes.SetDealID: {
      return {
        ...state,
        deal_id: action.dealID
      };
    }
    case AppActionTypes.SetReferrerID: {
      return {
        ...state,
        referrer_id: action.referrerID
      };
    }
    case AppActionTypes.SetPartnerID: {
      return {
        ...state,
        partner_id: action.partnerID
      };
    }
    case AppActionTypes.SetAgentID: {
      return {
        ...state,
        agent_id: action.agentID
      };
    }
    case AppActionTypes.SetInitialCountry: {
      return {
        ...state,
        initial_country: action.country
      };
    }
    case AppActionTypes.SetKazooInstanceId: {
      return {
        ...state,
        kazoo_instance_id: action.kazooInstanceId
      }
    }
    case AppActionTypes.SetChargebeeAccountId: {
      return {
        ...state,
        chargebee_account: action.chargebeeAccountId
      }
    }
    case AppActionTypes.SetAvailablePaymentMethods: {
      return {
        ...state,
        payment_methods_available: action.payment_methods_available
      }
    }
    case AppActionTypes.SetApiToken: {
      return {
        ...state,
        api_token: action.api_token
      }
    }
    case AppActionTypes.SetSendSms: {
      return {
        ...state,
        send_sms: action.send_sms
      }
    }
    default: {
      return state;
    }
  }
}
