import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { ChoosePlanState, State } from "../reducers";
import { SavePlanId, SavePlanQuantity } from "../actions";
import {
  selectPlanId,
  selectDescription,
  selectDiscount,
  selectPrice,
  selectPlanQuantity,
  selectIsVoiceChat
} from "../selectors";

@Injectable()
export class ChoosePlanFacade {
  planId$ = this.store.pipe(select(selectPlanId));
  description$ = this.store.pipe(select(selectDescription));
  discount$ = this.store.pipe(select(selectDiscount));
  price$ = this.store.pipe(select(selectPrice));
  quantity$ = this.store.pipe(select(selectPlanQuantity));
  isVoiceChat$ = this.store.pipe(select(selectIsVoiceChat));

  constructor(private store: Store<ChoosePlanState>) {}

  savePlanId(payload: State) {
    this.store.dispatch(new SavePlanId(payload));
  }

  savePlanQuantity(quantity: number) {
    this.store.dispatch(new SavePlanQuantity(quantity));
  }
}
