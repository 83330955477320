import { createSelector } from "@ngrx/store";
import { AppState, State } from "../reducers";
import { getData } from "../../model/app.model";
import { selectInfoStatus } from 'src/app/domains/info/store/selectors';

export const selectAppState = (state: AppState) => state.reducer;

export const selectLocale = createSelector(
  selectAppState,
  (state: State) => state.locale
);

export const selectStep = createSelector(
  selectAppState,
  (state: State) => state.step
);

export const selectData = createSelector(selectStep, (step: string) => {
  return getData(step);
});

// Tried to combine different facade observable but making a dedicate
// selector is easier and less prone to errors
// Should only be used after info step is passed
export const selectDataWithCountry = createSelector(selectInfoStatus,
  selectStep,
  (info, step) => {
    return {
      data: getData(step),
      country: info.country.toLowerCase()
    }
  })

export const selectProgress = createSelector(
  selectAppState,
  (state: State) => state.progress
);

export const selectIsToken = createSelector(
  selectAppState,
  (state: State) => state.isToken
);

export const selectStart = createSelector(
  selectAppState,
  (state: State) => state.started
);

export const selectDealID = createSelector(
  selectAppState,
  (state: State) => state.deal_id
);

export const selectReferrerID = createSelector(
  selectAppState,
  (state: State) => state.referrer_id
);

export const selectPartnerID = createSelector(
  selectAppState,
  (state: State) => state.partner_id
);

export const selectAgentID = createSelector(
  selectAppState,
  (state: State) => state.agent_id
);

export const selectInitialCountry = createSelector(
  selectAppState,
  (state: State) => state.initial_country
);

export const selectDataAndInitialCountry = createSelector(
  selectData,
  selectInitialCountry,
  (data, initialCountry) => {
    return {
      urlCountry: initialCountry,
      initialCountry: data.initialCountry
    }
  }
);

export const selectKazooInstanceId = createSelector(
  selectAppState,
  (state: State) => state.kazoo_instance_id
);

export const selectChargebeeAccountId = createSelector(
  selectAppState,
  (state: State) => state.chargebee_account
);

export const selectAvailablePaymentMethods = createSelector(
  selectAppState,
  (state: State) => state.payment_methods_available
);

export const selectApiToken = createSelector(
  selectAppState,
  (state: State) => state.api_token
);

export const selectSendSms = createSelector(
  selectAppState,
  (state: State) => state.send_sms
);
