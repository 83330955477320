import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

import { AuthService } from "../services/auth.service";
import { Logger } from "src/app/core/logger/logger.service";
import { AppFacade } from '../../app/store';
import { TokenResponse } from '../models/auth.model';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  log = new Logger("AuthGuard");

  constructor(
    private router: Router,
    private appFacade: AppFacade,
    private authService: AuthService
    ) { }

  canActivate(): any {
    return this.appFacade.step$.pipe(
      withLatestFrom(this.appFacade.apiToken$),
      concatMap((v: [string, TokenResponse]) => {
        if(v[1] === null) {
          const apiToken = this.getCookies()['CF_Authorization'];
          if(apiToken) {
            return this.authService.validateCfToken(apiToken)
            .pipe(map((res: TokenResponse) => {
              if(res) {
                this.appFacade.setApiToken(res);
                return true;
              } else {
                this.router.navigate(['/attiva_ora_unificato/signup']);
                return false
              }
            }),
              catchError(() => {
                return of(false)
              })
            );
          } else {
            this.router.navigate(['/attiva_ora_unificato/signup']);
          }
        }
      return of(true);
    }))
  }

  private getCookies() {
    const cookies = document.cookie.split(';').reduce((res, c) => {
      const [key, val] = c.trim().split('=').map(decodeURIComponent)
      try {
        return Object.assign(res, { [key]: JSON.parse(val) })
      } catch (e) {
        return Object.assign(res, { [key]: val })
      }
    }, {});
    return cookies;
  }
}
