import { Injectable } from "@angular/core";
import { ActivationState } from "../reducers";
import { Store, select } from "@ngrx/store";
import {
  selectAccount,
  selectRequestId,
  selectNewNumber,
  selectActivationLoading,
  selectActivationError,
  getUser,
  createLegacyAccount,
  selectActivationData,
  selectRomulusActivationData
} from "../selectors";
import { Account, IRomulusAccountRequest, ROMULUS_PAYMENT_METHOD_TYPES } from "../../models";
import { SendAccount, SetNewNumber } from "../actions";
import { map, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { AccountNumberService } from 'src/app/domains/account-number';

@Injectable()
export class ActivationFacade {
  account$ = this.store.pipe(select(selectAccount));
  requestId$ = this.store.pipe(select(selectRequestId));
  newNumber$ = this.store.pipe(select(selectNewNumber));
  loading$ = this.store.pipe(select(selectActivationLoading));
  error$ = this.store.pipe(select(selectActivationError));
  user$ = this.store.pipe(select(getUser));
  legacyAccount$ = this.store.pipe(select(createLegacyAccount));

  constructor(private store: Store<ActivationState>, private verificationDataService: AccountNumberService) { }

  sendAccount(account: Account) {
    this.store.dispatch(new SendAccount(account));
  }

  setNewNumber(payload: string) {
    this.store.dispatch(new SetNewNumber(payload));
  }

  getActivationDataAsFormData() {
    // get data from the store and return an observable that will resolve
    // to a formdata object to send to backend
    // if vd exists add them. If documents exists, generate the array and add them
    // add all to formData and return
    return this.store.pipe(select(selectActivationData), take(1), map(activationData => {
      let createRequest = { ...activationData.legacyAccount };
      if(!activationData.pData){
        let vd;
        let documents = [];
        let formData = new FormData();
        if (activationData.verificationData) {
          vd = this.verificationDataService.parseVerificationDataForValidation(
            activationData.verificationData,
            activationData.verificationDataRequirements[activationData.verificationDataType]);
          vd['type'] = activationData.verificationDataType;
          createRequest['verification_data'] = vd;
          if (vd.documents) {
            documents = this.verificationDataService.getDocumentsAsArray(
              activationData.verificationData,
              activationData.verificationDataRequirements[activationData.verificationDataType]
            );
          }
        }
        documents.forEach(d => {
          formData.append('documents', d.file, d.file_name);
        });
        return { createRequest: createRequest, formData: formData };
      } else {
        createRequest.is_portability = true;
        createRequest.dids = activationData.numbers;
        return {createRequest: createRequest, formData: activationData.pData}
      }
    }))
  }

  getRomulusActivation() {
    return this.store.pipe(select(selectRomulusActivationData), take(1), map(activationData => {
      let voxloud_account = { ...activationData.legacyAccount };
      voxloud_account.is_trial = true; //force number to be temporary
      let romulus_account: IRomulusAccountRequest = {
        plan: voxloud_account.plan,
        addons: voxloud_account.addons,
        payment_method: null
      }
      if(voxloud_account.payment_method.sepa_direct_debit) {
        romulus_account.payment_method = {
          company_name: voxloud_account.payment_method.sepa_direct_debit.company_name,
          email: voxloud_account.payment_method.sepa_direct_debit.email,
          first_name: voxloud_account.payment_method.sepa_direct_debit.first_name,
          last_name: voxloud_account.payment_method.sepa_direct_debit.last_name,
          iban: voxloud_account.payment_method.sepa_direct_debit.iban,
          type: ROMULUS_PAYMENT_METHOD_TYPES.SEPA,
        }
      } else if(voxloud_account.payment_method.card) {
        romulus_account.payment_method = {
          type: ROMULUS_PAYMENT_METHOD_TYPES.CARD,
          token: activationData.rolumusCardToken
        }
      }
        return { voxloud_account: voxloud_account, romulus_account: romulus_account}
    }));
  }
}
