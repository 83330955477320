import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ActivationState, State } from "../reducers";
import { selectInfoStatus } from "src/app/domains/info/store/selectors";
import { selectSignupStatus } from "src/app/domains/signup/store/selectors/signup.selectors";
import {
  Account,
  PAYMENT_TYPES,
  LegacyAccount,
  EquipmentUser
} from "../../models";
import { environment } from "src/environments/environment";
import {
  getAddons,
  selectAddonStatus
} from "src/app/domains/addon/store/selectors";
import {
  selectPlanId,
  selectPlanQuantity
} from "src/app/domains/choose-plan/store/selectors";
import {
  selectPaymentType,
  selectCouponCode,
  selectPaymentData,
  selectRomulusCardData
} from "src/app/domains/pay/store/selectors";
import { selectPhoneNumber } from "src/app/domains/verify-phone/store/selectors";
import { selectStarterKitID, selectStarterKitEquipments } from "src/app/domains/starter-kit/store/selectors";
import {
  selectReferrerID,
  selectPartnerID,
  selectAgentID,
  selectDealID,
  selectKazooInstanceId,
  selectChargebeeAccountId
} from "src/app/domains/app/store";
import { selectCoverageData } from "src/app/domains/coverage/store/selectors/coverage.selectors";
import { WifiActivationRequest } from "src/app/domains/coverage/models/coverage.model";
import { selectPortabilityDocuments, selectPortabilityNumbers, selectVerificationData, selectVerificationDataRequirements, selectVerificationDataType } from 'src/app/domains/account-number';
import { VDValues, VerificationDataItem, VerificationDataRequirements } from 'ng-voxloud';
import { VerificationData } from 'ng-voxloud/lib/vd-table-readonly/vd-table-readonly.model';
import { PortabilityDocuments, PortabilityNumber } from "src/app/domains/account-number/models/portability.model";

export const selectActivationState = createFeatureSelector<ActivationState>(
  "activation"
);

export const getUser = createSelector(
  selectSignupStatus,
  selectInfoStatus,
  getAddons,
  selectPlanId,
  selectPaymentType,
  selectCouponCode,
  (
    signup,
    info,
    addons,
    plan_id,
    paymentType?: PAYMENT_TYPES,
    couponCode?: string
  ) => {
    let sf;
    if (info.shipping_name) {
      sf = {
        address_line_1: `${info.shipping_address.route}, ${info.shipping_address.street_number}`,
        city: info.shipping_address.locality,
        country: info.shipping_address.country.short_name,
        province: info.shipping_address.province,
        zip: info.shipping_address.postal_code,
        primary: true,
        email: signup.email,
        phone: "",
        company: "",
        first_name: info.shipping_name.split(" ")[0],
        last_name: info.shipping_name.split(" ")[1] || "BUG"
      };
    }
    let acc: Account = {
      company: {
        address: `${info.billing_address.route}, ${info.billing_address.street_number}`,
        city: info.billing_address.locality,
        country: info.billing_address.country.short_name,
        name: info.business_name,
        province: info.billing_address.province,
        vat: info.vat.vat,
        zip: info.billing_address.postal_code
      },
      request_id: null,
      status: null,
      subscription: {
        plan_id: plan_id || environment.trialPlan,
        trial: plan_id ? false : true,
        payment_method: null,
        ...(info.shipping_name ? { shipping_address: sf } : {}),
        ...(addons ? { addons: addons } : {}),
        ...(addons ? { one_time_charges: { "shipping-v1": 1 } } : {})
      },
      did: {
        city_id: null,
        number: ""
      },
      user: {
        email: signup.email,
        name: signup.fullname,
        password: signup.password
      }
    };
    return acc;
  }
);

export const getUserAdmin = createSelector(
  selectSignupStatus,
  selectPhoneNumber,
  (signup, number) => {
    const split = signup.fullname.trim().split(" ");
    const first_name = split[0];
    const last_name = split.slice(1).join(' ');
    let user_admin = {
      first_name,
      last_name,
      email: signup.email,
      password: signup.password,
      phone: number
    };
    return user_admin;
  }
);

export const getInfo = createSelector(
  selectSignupStatus,
  selectInfoStatus,
  (signup, info) => {
    const billingAddress = {
      address_line_1: `${info.billing_address.route}, ${info.billing_address.street_number}`,
      zip: info.billing_address.postal_code,
      city: info.city_name,
      province: info.billing_address.province,
      country: info.billing_address.country.short_name,
    };
    let fullInfo = {
      billing_info: {
        company_name: info.business_name,
        vat: info.vat.vat,
        invoicing_emails: [signup.email],
        ...billingAddress
      },
      shipping_info: info.correspond
        ? {
          name: signup.fullname,
          ...billingAddress
        }
        : {
          name: signup.fullname,
          address_line_1: `${info.shipping_address.route}, ${info.shipping_address.street_number}`,
          zip: info.shipping_address.postal_code,
          city: info.city_name,
          province: info.shipping_address.province,
          country: info.shipping_address.country.short_name
        },
      city_id: info.city_id,
      area_code: info.prefix,
      country_code: info.country,
      business_category: info.business_category,
      business_type: info.business_type || null,
      other_business_type: info.other_business_type || null
    };
    return fullInfo;
  }
);

export const getProducts = createSelector(
  selectPlanId,
  selectPlanQuantity,
  selectAddonStatus,
  selectStarterKitID,
  (plan_id, plan_qnt, addons, starterKit_id) => {
    return {
      plan: {
        id: plan_id,
        quantity: plan_qnt
      },
      addons: addons.status.addons,
      starter_kit: {
        id: starterKit_id || "none"
      }
    };
  }
);

export const getPaymentMethod = createSelector(
  selectPaymentType,
  selectPaymentData,
  (payment_type, payment_data) => {
    let payment_method = {
      card:
        payment_type === 'creditcard' || payment_type === 'creditcard_roumulus'
          ? {
            payment_intent: payment_data.stripeToken
          }
          : null,
      sepa_direct_debit: 
        payment_type === "sepa_direct_debit"
          ? payment_data.sepaData : null
    };
    if (
      payment_method.card === null
      && payment_method.sepa_direct_debit === null
    ) {
      return null;
    } else {
      return payment_method;
    }
  }
);

export const getIds = createSelector(
  selectDealID,
  selectReferrerID,
  selectPartnerID,
  selectAgentID,
  selectKazooInstanceId,
  selectChargebeeAccountId,
  (deal_id, referrer_id, partner_id, agent_id, kazoo_instance_id, chargebee_account) => {
    let ids = {
      deal_id: deal_id ? deal_id : null,
      referrer_id: referrer_id ? referrer_id : null,
      partner_id: partner_id ? partner_id : null,
      agent_id: agent_id ? agent_id : null,
      kazoo_instance_id: kazoo_instance_id ? kazoo_instance_id : null,
      chargebee_account: chargebee_account ? chargebee_account : null
    };
    return ids;
  }
);

export const getUsersEquipment = createSelector(
  selectSignupStatus,
  selectStarterKitEquipments,
  (signup, equipments) => {
    if (equipments.length !== 0) {
      return equipments.filter(
        filtered => filtered.user && signup.fullname !== filtered.user.name && signup.email !== filtered.user.email
      )
        .map((e): EquipmentUser => {
          if (e.user && signup.fullname !== e.user.name && signup.email !== e.user.email) {
            const split = e.user.name.trim().split(" ");
            const firstName = split[0];
            const lastName = split.slice(1).join(' ');
            return {
              first_name: firstName,
              last_name: lastName,
              email: e.user.email
            }
          }
        })
    } return [];
  }
);

export const createLegacyAccount = createSelector(
  getUserAdmin,
  getInfo,
  getProducts,
  getPaymentMethod,
  selectCouponCode,
  getUsersEquipment,
  getIds,
  selectCoverageData,
  (user_admin, info, products, payment_method, coupon_id, new_users, ids, coverageData: WifiActivationRequest) => {
    let legacyAccount: LegacyAccount = {
      user_admin: user_admin,
      billing_info: info.billing_info,
      shipping_info: info.shipping_info || null,
      plan: products.plan,
      addons: products.addons,
      starter_kit: products.starter_kit || null,
      payment_method: payment_method || null,
      coupon_id: coupon_id,
      is_trial: products.plan.id.toLowerCase().includes('trial'),
      deal_id: ids.deal_id || null,
      referrer_id: ids.referrer_id || null,
      partner_id: ids.partner_id || null,
      agent_id: ids.agent_id || null,
      notes: null,
      page_url: null,
      prefix: info.area_code || null,
      country_code: info.country_code || null,
      kazoo_instance_id: ids.kazoo_instance_id,
      chargebee_account: ids.chargebee_account,
      users: new_users,
      has_wifi: coverageData ? true : false,
      wifi_request: coverageData || null,
      business_category: info.business_category,
      business_type: info.business_type || null,
      other_business_type: info.other_business_type || null
    };
    return legacyAccount;
  }
);

export const selectPortabilityData = createSelector(
  selectPortabilityDocuments,
  (documents: PortabilityDocuments) => {
    let getExtension = (v:File) => {
      return v.name.substring(v.name.lastIndexOf('.'));
    }
    if(documents){
      let fd = new FormData();
      fd.append('documents', documents.module, `portability_template_signed${getExtension(documents.module)}`);
      fd.append('documents', documents.invoice, `last_invoice${getExtension(documents.invoice)}`);
      if(documents.identity){
        fd.append('documents', documents.identity, `identity${getExtension(documents.identity)}`)
      }
      return fd;
    } else {
      return null;
    }
  }
)

export const selectNumberToPort = createSelector(
  selectPortabilityNumbers,
  (numbers) => numbers
)

// creating a new selector instead of reusing the other
// because there is a limit on the number of selectors
// that can be passed to the function
export const selectActivationData = createSelector(
  createLegacyAccount,
  selectVerificationData,
  selectVerificationDataRequirements,
  selectVerificationDataType,
  selectPortabilityData,
  selectPortabilityNumbers,
  (legacyAccount: LegacyAccount, vd: VDValues, vdr: VerificationDataRequirements, vdType: string, pData: FormData, numbers: PortabilityNumber[]) => {
    return {
      legacyAccount: legacyAccount,
      verificationData: vd,
      verificationDataRequirements: vdr,
      verificationDataType: vdType,
      pData: pData,
      numbers: numbers
    }
  }
)

export const selectRomulusActivationData = createSelector(
  createLegacyAccount,
  selectRomulusCardData,
  (legacyAccount: LegacyAccount, rolumusCardToken: string) => {
    return {
      legacyAccount: legacyAccount,
      rolumusCardToken, 
    }
  }
)

export const selectActivationStatus = createSelector(
  selectActivationState,
  (state: ActivationState) => state.status
);

export const selectAccount = createSelector(
  selectActivationStatus,
  (state: State) => state.account
);

export const selectRequestId = createSelector(
  selectActivationStatus,
  (state: State) => state.requestId
);

export const selectNewNumber = createSelector(
  selectActivationStatus,
  (state: State) => state.newNumber
);

export const selectActivationLoading = createSelector(
  selectActivationStatus,
  (state: State) => state.loading
);

export const selectActivationError = createSelector(
  selectActivationStatus,
  (state: State) => state.error
);
