import { PHONE_NUMBER_CLASSES, PHONE_NUMBER_TYPES, PortabilityNumber } from '../../account-number/models/portability.model';
import { Addon } from '../../addon/model';
import { WifiActivationRequest } from '../../coverage/models/coverage.model';
import { BusinessCategory, BusinessType } from '../../info/models/info.model';

export interface Company {
  address: string;
  city: string;
  country: string;
  name: string;
  province: string;
  vat: string;
  zip: string;
}

export interface Subscription {
  plan_id: string;
  trial: boolean;
  coupon_id?: string;
  payment_method: PaymentMethod;
  one_time_charges?: {
    [key: string]: number;
  };
  addons?: {
    [key: string]: number;
  };
  shipping_address?: {
    address_line_1: string;
    address_line_2?: string;
    address_line_3?: string;
    city: string;
    company: string;
    country: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    primary: true;
    province: string;
    zip: string;
  };
}

export interface User {
  email: string;
  name: string;
  password: string;
}

export interface PaymentMethod {
  type: PAYMENT_TYPES;
  token: string;
}

export enum PAYMENT_TYPES {
  CC = "creditcard",
  SEPA = 'sepa_direct_debit',
  CC_ROUMULUS = 'creditcard_roumulus'
}

export interface SepaDataModel {
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
  iban: string;
}

export interface Did {
  city_id: number;
  number: string;
}

export interface EquipmentUser {
  first_name: string;
  last_name: string;
  email: string;
}

export interface Account {
  company: Company;
  request_id: string;
  status: string;
  subscription: Subscription;
  did: Did;
  user: User;
}

export interface LegacyAccount {
  user_admin: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    phone: string;
  };
  billing_info: {
    company_name: string;
    vat: string;
    address_line_1: string;
    address_line_2?: string;
    address_line_3?: string;
    zip: string;
    city: string;
    province: string;
    country: string;
  };
  shipping_info?: {
    name: string;
    address_line_1: string;
    address_line_2?: string;
    address_line_3?: string;
    zip: string;
    city: string;
    province: string;
    country: string;
  };
  plan: {
    id: string;
    quantity: number;
  };
  addons: Addon[];
  starter_kit?: {
    id: string;
  };
  payment_method: {
    card?: {
      payment_intent: string;
    };
    sepa_direct_debit?: SepaDataModel
  };
  wifi_request?: WifiActivationRequest;
  users?: EquipmentUser[];
  is_trial: boolean;
  has_wifi: boolean;
  coupon_id?: string;
  is_portability?: boolean;
  dids?: PortabilityNumber[];
  deal_id?: string;
  referrer_id?: string;
  partner_id?: string;
  agent_id?: string;
  prefix?: string;
  country_code?: string;
  kazoo_instance_id?: string;
  notes?: string;
  page_url?: string;
  recaptcha_response?: string;
  chargebee_account?: string;
  business_category: BusinessCategory;
  business_type : BusinessType;
  other_business_type : string;
}

export interface IRomulusAccountRequest {
  addons: Addon[];
  payment_method: {
    type: ROMULUS_PAYMENT_METHOD_TYPES,
    iban?:	string;
    email?:	string;
    first_name?:	string;
    last_name?:	string;
    company_name?: string;
    token?: string;
  };
  plan: {
    id: string;
    quantity: number;
  }
}

export enum ROMULUS_PAYMENT_METHOD_TYPES {
  CARD = 'card',
  SEPA = 'sepa_direct_debit'
}

export enum DID_STATUS {
  CREATED = 'CREATED',
  TEMPORARY_ACTIVE = 'TEMPORARY_ACTIVE',
  ACTIVE = 'ACTIVE',
  DISEBLED = 'DISABLED',
  DELETED = 'DELETED',
  WAITING_VERIFICATION_DATA = 'WAITING_VERIFICATION_DATA',
  WAITING_PROVIDER_VALIDATION = 'WAITING_PROVIDER_VALIDATION',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  SCHEDULED_DELETION = 'SCHEDULED_DELETION',
  ONGOING_PORTABILITY = 'ONGOING_PORTABILITY'
}

export interface ActivationResponseDid {
  allocation_date: string,
  area_code: string,
  company_id: number,
  country: string,
  creation_date: string,
  did_class: PHONE_NUMBER_CLASSES,
  did_type: PHONE_NUMBER_TYPES,
  id: number,
  is_from_pool: boolean,
  number: string,
  provider: string,
  status: DID_STATUS,
  verification_data_id: number
}

export interface ActivationResponse {
  did?: string;
  login_url: string;
  did_status?: DID_STATUS;
  is_from_pool?: boolean;
  added_dids?: ActivationResponseDid[];
}

export interface IRomulusActivationResponse {
  voxloud_account: ActivationResponse,
  romulus_account: IRomulusActivationData
}

export interface IRomulusActivationData {
  id: string;
  errors?: IRomulusActivationError;
  success: boolean;
}

export interface IRomulusActivationError {
  message: string;
  code: number;
}
