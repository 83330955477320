import { Action } from "@ngrx/store";
import { PAYMENT_TYPES, SepaDataModel } from "src/app/domains/activation/models";

export enum PayActionsTypes {
  StoreToken = "[Pay] Store Token",
  StorePayment = "[Pay] Store Payment",
  StoreStripePublicKey = "[Pay]",
  StoreRomulusStripePublicKey = "[Pay] Store Romulus Pb Key",
  StoreCoupon = "[Pay] Store Coupon",
  StoreSepaData = "[Pay]"
}

export class StoreToken implements Action {
  readonly type = PayActionsTypes.StoreToken;
  constructor(
    readonly token: string,
    readonly paymentType?: PAYMENT_TYPES,
    readonly couponCode?: string
  ) { }
}

export class StorePayment implements Action {
  readonly type = PayActionsTypes.StorePayment;
  constructor(
    readonly paymentType: PAYMENT_TYPES,
    readonly stripeToken?: string,
    readonly stripeRomulusToken?: string,
    readonly sepaData?: SepaDataModel,
    readonly couponCode?: string
  ) { }
}

export class StoreStripePublicKey implements Action {
  readonly type = PayActionsTypes.StoreStripePublicKey;
  constructor(
    readonly stripeKey: string
  ) { }
}

export class StoreRomulusStripePublicKey implements Action {
  readonly type = PayActionsTypes.StoreRomulusStripePublicKey;
  constructor(
    readonly stripeKey: string
  ) { }
}

export class StoreCoupon implements Action {
  readonly type = PayActionsTypes.StoreCoupon;
  constructor(
    readonly couponId: string
  ) { }
}

export type PayActions = StoreToken | StorePayment | StoreCoupon | StoreStripePublicKey | StoreRomulusStripePublicKey ;
