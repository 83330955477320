import { createSelector, createFeatureSelector } from "@ngrx/store";
import {
  selectAddonStatus
} from "src/app/domains/addon/store/selectors";
import { selectInfoStatus } from "src/app/domains/info/store/selectors";
import {
  selectPlanId,
  selectPlanQuantity
} from "src/app/domains/choose-plan/store/selectors";
import { selectSignupStatus } from "src/app/domains/signup/store/selectors/signup.selectors";
import { State as infoState } from "src/app/domains/info/store/reducers";
import { State as signupState } from "src/app/domains/signup/store/reducers";
import { AddonState } from "src/app/domains/addon/store/reducers";
import { PayState } from "../reducers";

import { EstimationAddon, EstimationRequest } from "../../model/pay.model";
import { selectPortabilityNumbers } from 'src/app/domains/account-number';
import { PortabilityNumber } from 'src/app/domains/account-number/models/portability.model';

export const selectPayStatus = createFeatureSelector("pay");

export const selectPaymentData = createSelector(
  selectPayStatus,
  (status: PayState) => status.payStatus
);

export const selectPaymentType = createSelector(
  selectPayStatus,
  (status: PayState) => status.payStatus.paymentType
);

export const selectCouponCode = createSelector(
  selectPayStatus,
  (status: PayState) => status.payStatus.couponCode
);

export const createEstimationRequest = createSelector(
  selectAddonStatus,
  selectInfoStatus,
  selectPlanId,
  selectPlanQuantity,
  selectSignupStatus,
  selectPortabilityNumbers,
  selectCouponCode,
  (
    addons: AddonState,
    info: infoState,
    plan_id: string,
    plan_qnt: number,
    signup: signupState,
    dids: PortabilityNumber[],
    couponCode: string
  ) => {
    let estimationRequest: EstimationRequest = {
      ...((addons.status.addons && addons.status.addons.length > 0) ? { addons: addons.status.addons } : {}),
      billing_info: {
        address_line_1: info.billing_address.route,
        city: info.billing_address.locality,
        company_name: info.business_name,
        country: info.billing_address.country.short_name,
        first_name: signup.fullname.split(" ")[0],
        invoicing_emails: [signup.email],
        is_taxable: true,
        taxable: true,
        last_name: signup.fullname.split(' ').slice(1).join(' '),
        province: info.billing_address.province,
        vat: info.vat.vat,
        zip: info.billing_address.postal_code
      },
      plan: { id: plan_id, quantity: plan_qnt },
      dids,
      couponId: couponCode ? couponCode : null
    };
    if (dids === null) {
      delete estimationRequest.dids;
    }
    return estimationRequest;
  }
);

export const createFreeEstimationRequest = createSelector(
  selectAddonStatus,
  selectInfoStatus,
  selectSignupStatus,
  (addons: AddonState, info: infoState, signup: signupState) => {
    let addonList: EstimationAddon[];
    if (
      !(
        Object.entries(addons.status).length === 0 &&
        addons.status.constructor === Object
      )
    ) {
      addonList = [];
      Object.keys(addons.status).forEach(key => {
        addonList[addons.status[key].name] = addons.status[key].quantity;
      });
    }
    let estimationRequest: EstimationRequest = {
      ...(Object.keys(addonList)[0] !== 'undefined'
        ? { addons: addonList }
        : {}),
      billing_info: {
        address_line_1: info.billing_address.route,
        city: info.billing_address.locality,
        company_name: info.business_name,
        country: info.billing_address.country.short_name,
        is_taxable: true,
        taxable: true,
        invoicing_emails: [signup.email],
        first_name: signup.fullname.split(' ')[0],
        last_name: signup.fullname.split(' ').slice(1).join(' '),
        province: info.billing_address.province,
        vat: info.vat.vat,
        zip: info.billing_address.postal_code
      },
      plan: {
        id: null
      }
    };
    return estimationRequest;
  }
);

export const selectStripePublicKey = createSelector(
  selectPayStatus,
  (status: PayState) => status.payStatus.stripePublicKey
);

export const selectRomulusStripePublicKey = createSelector(
  selectPayStatus,
  (status: PayState) => status.payStatus.stripeRomulusPublicKey
);

export const selectSepaData = createSelector(
  selectPayStatus,
  (status: PayState) => status.payStatus.sepaData
);

export const selectRomulusCardData = createSelector(
  selectPayStatus,
  (status: PayState) => status.payStatus.stripeRomulusToken
)
