import { flowStepsConfigDebug } from './flow-debug.model';
import { flowStepsConfigGB } from './flow-gb.model';
import { flowStepsConfigInternals } from './flow-internals.model';
import { flowStepsConfigIT } from './flow-it.model';
import { FlowConfig, FlowData } from './flow.model';

// config should be of the following format: { 'parent-route': [steps] }
// nested elements are not supported
export const flowStepsConfig: FlowConfig = {
  // ...flowStepsConfigDebug,
  ...flowStepsConfigIT,
  ...flowStepsConfigGB,
  ...flowStepsConfigInternals,
  // NOTE: uncomment for testing
  ...flowStepsConfigDebug
};

// store-related stuff
export enum DIRECTIONS {
  FORWARD,
  BACKWARD,
  NOWHERE
}

export function calculateProgress(path: string): number {
  const [flow, step] = path.split(".");
  const steps = flowStepsConfig[flow].steps;
  return Math.ceil(
    (100 * steps.indexOf(step)) / flowStepsConfig[flow].steps.length
  );
}

export function getFirstStep(path: string) {
  const flow = path.split(".")[0];
  return `${flow}.${flowStepsConfig[flow]?.steps[0]}`;
}

export function getInitialStep(config: FlowConfig) {
  const locationFlow = location.pathname.split('/')[1];
  const flow = Object.keys(config).includes(locationFlow) ? locationFlow : Object.keys(config)[0];
  return `${flow}.${config[flow].steps[0]}`;
}

export function getNextStep(path: string): string {
  const [flow, step] = path.split(".");
  const steps = flowStepsConfig[flow].steps;
  const i = steps.indexOf(step);
  return `${flow}.${steps[i + 1] || steps[0]}`;
}

export function getNextNStep(path: string, n: number): string {
  const [flow, step] = path.split(".");
  const steps = flowStepsConfig[flow].steps;
  let i: number;
  let currentStepIndex: number;
  steps.forEach((s, index) => {
    if(step === s) { currentStepIndex = index; }
    if(currentStepIndex+n-1 === index) {
      i = index;
    }
  });
  return `${flow}.${steps[i]}`
}

export function getPrevStep(path: string): string {
  const [flow, step] = path.split(".");
  const steps = flowStepsConfig[flow].steps;
  const i = steps.indexOf(step);
  return `${flow}.${steps[i - 1] || steps[0]}`;
}

export function getStepNavigationDirection(
  source: string,
  dest: string
): DIRECTIONS {
  const flow = source.split("/")[1];
  const sourceStep = source.split("/")[2];
  const destStep = dest.split("/")[2];
  const sourceIndex = flowStepsConfig[flow].steps.indexOf(sourceStep);

  if (sourceIndex === -1) {
    return DIRECTIONS.NOWHERE;
  }
  if (flowStepsConfig[flow].steps[sourceIndex + 1] === destStep) {
    return DIRECTIONS.FORWARD;
  }
  if (flowStepsConfig[flow].steps[sourceIndex - 1] === destStep) {
    return DIRECTIONS.BACKWARD;
  }
}

export function getData(path: string): FlowData {
  const flow = path.split(".")[0];
  return flowStepsConfig[flow].data;
}
