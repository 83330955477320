import { Injectable } from "@angular/core";
import { State, AppState } from "../reducers";
import { Store, select } from "@ngrx/store";
import {
  selectLocale,
  selectStep,
  selectProgress,
  selectReferrerID,
  selectPartnerID,
  selectAppState,
  selectAgentID,
  selectData,
  selectStart,
  selectIsToken,
  selectDealID,
  selectDataWithCountry,
  selectInitialCountry,
  selectDataAndInitialCountry,
  selectKazooInstanceId,
  selectChargebeeAccountId,
  selectApiToken,
  selectAvailablePaymentMethods,
  selectSendSms
} from "../selectors";
import {
  SetLocale,
  IncrementStep,
  DecrementStep,
  SilentIncrementStep,
  SilentDecrementStep,
  JumpToFirstStep,
  UpdateStepProgress,
  SetReferrerID,
  SetAgentID,
  SetPartnerlID,
  SetStep,
  SetStart,
  SetIsToken, SetDealID, SetInitialCountry, SetKazooInstanceId, SkipStarterKits, SkipNextNSteps, SetChargebeeAccountId, SetApiToken, SetAvailablePaymentMethods,
  SetSendSms
} from "../actions";
import { TokenResponse } from 'src/app/domains/auth/models/auth.model';

@Injectable({
  providedIn: "root"
})
export class AppFacade {
  constructor(private store: Store<AppState>) { }
  
  locale$ = this.store.pipe(select(selectLocale));
  step$ = this.store.pipe(select(selectStep));
  progress$ = this.store.pipe(select(selectProgress));
  data$ = this.store.pipe(select(selectData));
  dataWithCountry$ = this.store.pipe(select(selectDataWithCountry));
  selectDataAndInitialCountry$ = this.store.pipe(select(selectDataAndInitialCountry))
  start$ = this.store.pipe(select(selectStart));
  isToken$ = this.store.pipe(select(selectIsToken));
  apiToken$ = this.store.pipe(select(selectApiToken));

  dealID$ = this.store.pipe(select(selectDealID));
  referrerID$ = this.store.pipe(select(selectReferrerID));
  partnerID$ = this.store.pipe(select(selectPartnerID));
  agentID$ = this.store.pipe(select(selectAgentID));
  initialCountry$ = this.store.pipe(select(selectInitialCountry));
  kazooInstanceId$ = this.store.pipe(select(selectKazooInstanceId));
  chargebeeAccountId$ = this.store.pipe(select(selectChargebeeAccountId));
  paymentMethodsAvailable$ = this.store.pipe(select(selectAvailablePaymentMethods));
  sendSms$ = this.store.pipe(select(selectSendSms));


  locale(loc: string) {
    this.store.dispatch(new SetLocale(loc));
  }

  setIsToken(isToken: boolean) {
    this.store.dispatch(new SetIsToken(isToken));
  }

  setStart(start: boolean) {
    this.store.dispatch(new SetStart(start));
  }

  incrementStep() {
    this.store.dispatch(new IncrementStep());
  }

  decrementStep() {
    this.store.dispatch(new DecrementStep());
  }

  silentIncrementStep() {
    this.store.dispatch(new SilentIncrementStep());
  }

  silentDecrementStep() {
    this.store.dispatch(new SilentDecrementStep());
  }

  // TODO: Remove when we don't have to hide stater kits
  skipStarterKits() {
    this.store.dispatch(new SkipStarterKits());
  }

  skipNextNSteps(n: number) {
    // it's more intuitive to say skip the next step instead
    // of go to the next's next step
    // but it's easier to work directly with the next step index so +1 is here
    this.store.dispatch(new SkipNextNSteps(n + 1));
  }

  jumpToFirstStep() {
    this.store.dispatch(new JumpToFirstStep());
  }

  updateStepProgress(r: string) {
    this.store.dispatch(new UpdateStepProgress(r));
  }

  setStep(s: string) {
    this.store.dispatch(new SetStep(s));
  }

  setDealId(id: string) {
    this.store.dispatch(new SetDealID(id));
  }

  setReferrerID(id: string) {
    this.store.dispatch(new SetReferrerID(id));
  }

  setPartnerID(id: string) {
    this.store.dispatch(new SetPartnerlID(id));
  }

  setAgentID(id: string) {
    this.store.dispatch(new SetAgentID(id));
  }

  setInitialCountry(country: string) {
    this.store.dispatch(new SetInitialCountry(country));
  }

  setKazooInstanceId(kazooInstanceId: string) {
    this.store.dispatch(new SetKazooInstanceId(kazooInstanceId));
  }

  setChargebeeAccountId(chargebeeAccountId: string) {
    this.store.dispatch(new SetChargebeeAccountId(chargebeeAccountId));
  }

  setAvailablePaymentMethods(paymentMethods: string[]) {
    this.store.dispatch(new SetAvailablePaymentMethods(paymentMethods));
  }

  setApiToken(token: TokenResponse) {
    this.store.dispatch(new SetApiToken(token));
  }

  setSendSms(send_sms: boolean) {
    this.store.dispatch(new SetSendSms(send_sms));
  }
}
