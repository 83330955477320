import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { AppState } from "src/app/domains/app/store";
import {
  createEstimationRequest,
  createFreeEstimationRequest,
  selectRomulusStripePublicKey,
  selectStripePublicKey
} from "../selectors";
import { StoreToken, StorePayment, StoreStripePublicKey, StoreCoupon, StoreRomulusStripePublicKey } from "../actions";
import { PAYMENT_TYPES, SepaDataModel } from "src/app/domains/activation/models";

@Injectable()
export class PayFacade {
  estimationRequest$ = this.store.pipe(select(createEstimationRequest));
  freeEstimationRequest$ = this.store.pipe(select(createFreeEstimationRequest));
  stripePublicKey$ = this.store.pipe(select(selectStripePublicKey));
  stripeRomulusPublicKey$ = this.store.pipe(select(selectRomulusStripePublicKey));


  constructor(private store: Store<AppState>) { }

  storeToken(token: string, paymentMethod?: PAYMENT_TYPES) {
    if (paymentMethod)
      this.store.dispatch(new StoreToken(token, paymentMethod));
    else this.store.dispatch(new StoreToken(token));
  }

  storePayment(
    paymentMethod: PAYMENT_TYPES,
    stripeToken?: string,
    stripeRomulusToken?: string,
    sepaData?: SepaDataModel,
    couponCode?: string
  ) {
    this.store.dispatch(
      new StorePayment(
        paymentMethod,
        stripeToken || null,
        stripeRomulusToken || null,
        sepaData || null,
        couponCode || null
      )
    );
  }

  storeStripePublicKey(key: string) {
    this.store.dispatch(new StoreStripePublicKey(key));
  }

  storeRomulusStripePublicKey(key: string) {
    this.store.dispatch(new StoreRomulusStripePublicKey(key));
  }
  storeCoupon(couponId: string) {
    this.store.dispatch(new StoreCoupon(couponId));
  }
}
